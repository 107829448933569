exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-studio-focus-area-tsx": () => import("./../../../src/pages/studio/focus-area.tsx" /* webpackChunkName: "component---src-pages-studio-focus-area-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-pages-studio-what-we-provide-tsx": () => import("./../../../src/pages/studio/what-we-provide.tsx" /* webpackChunkName: "component---src-pages-studio-what-we-provide-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

